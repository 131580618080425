<template>
  <div class="news-view-by">
    <div class="news-view-by-content button" v-if="!isAdminNews" @click="viewNews(news)">
      <iconic name="eye" />
    </div>
    <div class="news-view-by-content button" v-else-if="isAdminNews" @click="viewNewsReaders(news)">
      <iconic name="eye" />

      <span>{{ news.readers.total }}</span>
    </div>
  </div>
</template>

<script>
import NewsMixing from "./NewsMixing";
export default {
  mixins: [NewsMixing],
  props: ["news"],
  data: function() {
    return {
      views: 0,
    };
  },
};
</script>

<style lang="scss">
.news-view-by {
  &-content {
    display: flex;
    align-items: center;
    gap: $mpadding/2;
  }
  .iconic {
    font-size: 1.5rem;
  }
}
</style>
